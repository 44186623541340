var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _vm.GreenSuppliers
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", xl: "12" } },
                [
                  _c("transition", { attrs: { name: "slide" } }, [
                    _c(
                      "div",
                      [
                        _c("div", { staticClass: "clearfix" }, [
                          _c(
                            "h1",
                            {
                              class: [
                                `${
                                  !_vm.$screen.sm
                                    ? "float-left main-page-title"
                                    : ""
                                }`,
                                { "is-pwa": _vm.$isPwa() },
                              ],
                            },
                            [_vm._v(_vm._s(this.caption1))]
                          ),
                        ]),
                        _vm.isDev
                          ? _c(
                              "b-card",
                              { attrs: { "no-body": "" } },
                              [
                                _c(
                                  "b-card-body",
                                  [
                                    _vm.isDev
                                      ? _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              { attrs: { sm: "12" } },
                                              [
                                                _c(
                                                  "h2",
                                                  { staticClass: "h4" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            50,
                                                            "Load Green Key suppliers"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              { attrs: { md: "6" } },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        5020,
                                                        "File to load"
                                                      ),
                                                      "label-for":
                                                        "accConfirmationFileBrowse",
                                                      "label-cols": 12,
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-file", {
                                                      ref: "pdfConfirmationFileId",
                                                      attrs: {
                                                        "browse-text":
                                                          _vm.FormMSG(
                                                            113,
                                                            "Browse"
                                                          ),
                                                        name: "test",
                                                        id: "accConfirmationFileBrowse",
                                                        accept: ".xlsx,.xml",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            51,
                                                            "Choose a file or drop it here..."
                                                          ),
                                                        "drop-placeholder":
                                                          _vm.FormMSG(
                                                            52,
                                                            "Drop file here..."
                                                          ),
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.submitConfirmationFile,
                                                      },
                                                      model: {
                                                        value: _vm.pdfFile,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.pdfFile = $$v
                                                        },
                                                        expression: "pdfFile",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              { attrs: { md: "6" } },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        5021,
                                                        "Filename"
                                                      ),
                                                      "label-for":
                                                        "docFileName",
                                                      "label-cols": 12,
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        disabled: true,
                                                        type: "text",
                                                        id: "docFileName",
                                                      },
                                                      model: {
                                                        value: _vm.fileName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.fileName = $$v
                                                        },
                                                        expression: "fileName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-modal",
                                  {
                                    staticClass: "modal-success",
                                    attrs: {
                                      "header-class":
                                        "header-class-modal-doc-package",
                                      title: this.FormMSG(104, "Success!"),
                                      "ok-variant": "success",
                                      "ok-only": "",
                                    },
                                    on: {
                                      ok: function ($event) {
                                        _vm.successModal = false
                                      },
                                    },
                                    model: {
                                      value: _vm.successModal,
                                      callback: function ($$v) {
                                        _vm.successModal = $$v
                                      },
                                      expression: "successModal",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(this.caption2) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-card",
                          { attrs: { "no-body": "" } },
                          [
                            _c(
                              "b-card-body",
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c("b-col", { attrs: { sm: "12" } }, [
                                      _c("h2", { staticClass: "h4" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.FormMSG(5024, "Filter list")
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "b-col",
                                      { attrs: { sm: "3" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: this.FormMSG(80, "Type"),
                                              "label-align-sm": "left",
                                              "label-for":
                                                "optionsForCategories",
                                            },
                                          },
                                          [
                                            _c("b-form-select", {
                                              attrs: {
                                                options:
                                                  _vm.optionsForCategories,
                                                id: "optionsForCategories",
                                              },
                                              on: {
                                                change: _vm.gsCategoryChanged,
                                              },
                                              model: {
                                                value: _vm.gsCategorySelected,
                                                callback: function ($$v) {
                                                  _vm.gsCategorySelected = $$v
                                                },
                                                expression:
                                                  "gsCategorySelected",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { sm: "3" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: this.FormMSG(
                                                81,
                                                "Country"
                                              ),
                                              "label-align-sm": "left",
                                              "label-for":
                                                "optionsForCountries",
                                            },
                                          },
                                          [
                                            _c("b-form-select", {
                                              attrs: {
                                                options:
                                                  _vm.optionsForCountries,
                                                id: "optionsForCountries",
                                              },
                                              on: {
                                                change: _vm.countryChanged,
                                              },
                                              model: {
                                                value: _vm.countrySelected,
                                                callback: function ($$v) {
                                                  _vm.countrySelected = $$v
                                                },
                                                expression: "countrySelected",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { sm: "3" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: this.FormMSG(83, "State"),
                                              "label-align-sm": "left",
                                              "label-for": "optionsForStates",
                                            },
                                          },
                                          [
                                            _c("b-form-select", {
                                              attrs: {
                                                options: _vm.optionsForStates,
                                                id: "optionsForStates",
                                              },
                                              on: { change: _vm.stateChanged },
                                              model: {
                                                value: _vm.stateSelected,
                                                callback: function ($$v) {
                                                  _vm.stateSelected = $$v
                                                },
                                                expression: "stateSelected",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("b-col", { attrs: { sm: "3" } }, [
                                      _c(
                                        "div",
                                        { staticClass: "filter-container" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "map-btn",
                                              on: {
                                                click: function ($event) {
                                                  _vm.isMappedSupplierModalOpen = true
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "icon-map",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: this.FormMSG(82, "Text"),
                                                "label-align-sm": "left",
                                                "label-for": "filterInput",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "search",
                                                      id: "filterInput",
                                                      placeholder: _vm.FormMSG(
                                                        44,
                                                        "Type to Search"
                                                      ),
                                                    },
                                                    model: {
                                                      value: _vm.filter,
                                                      callback: function ($$v) {
                                                        _vm.filter = $$v
                                                      },
                                                      expression: "filter",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm.filter,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.filter = ""
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-times",
                                                            attrs: {
                                                              "aria-hidden":
                                                                "true",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _vm.NumGreenSuppliersFiltered > 0
                              ? _c(
                                  "b-row",
                                  _vm._l(
                                    _vm.GreenSuppliersFiltered,
                                    function (item, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: index,
                                          attrs: { cols: "12", md: "6" },
                                        },
                                        [
                                          _c(
                                            "b-card",
                                            {
                                              staticClass:
                                                "mx-auto my-4 shadow card-tip",
                                              attrs: { "no-body": "" },
                                            },
                                            [
                                              _c("b-card-body", [
                                                _c("div", [
                                                  _c(
                                                    "strong",
                                                    { staticClass: "h5" },
                                                    [_vm._v(_vm._s(item.name))]
                                                  ),
                                                ]),
                                                _c("div", [
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticClass:
                                                        "text-primary",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.GetTextFromMenuNumberAndMenuValue(
                                                            1017,
                                                            item.type
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "address-block position-relative my-3",
                                                    attrs: { href: "#" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.goThere(item)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "address-icon position-absolute",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "icon-location-pin",
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "address-content",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              item.address
                                                                .street
                                                            ) +
                                                            " , " +
                                                            _vm._s(
                                                              item.address.zip
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              item.address.city
                                                            )
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              item.address.state
                                                            )
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              item.address
                                                                .country
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c("div", [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "d-inline-block text-truncate",
                                                      staticStyle: {
                                                        "max-width": "100%",
                                                      },
                                                      attrs: {
                                                        href: item.webSite,
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.webSite)
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]),
                                              _c("b-card-footer", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "line-height": "30px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-6",
                                                          },
                                                          [
                                                            _c(
                                                              "b-button",
                                                              {
                                                                attrs: {
                                                                  variant:
                                                                    "default",
                                                                  size: "sm",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.transferToProject(
                                                                        item.id
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "icon-share mr-1",
                                                                }),
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        5027,
                                                                        "Add to current project"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-6 green-key",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "pull-right",
                                                                attrs: {
                                                                  id: `green-key-${index}`,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "key mr-2",
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fa fa-key",
                                                                      attrs: {
                                                                        "aria-hidden":
                                                                          "true",
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "label",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.labelOrigin
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex text-label",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          53,
                                                                          "Label"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            item.user.name !=
                                                              "" ||
                                                            item.user
                                                              .firstName != ""
                                                              ? _c(
                                                                  "b-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      target: `green-key-${index}`,
                                                                      placement:
                                                                        "left",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tIntroduced by"
                                                                    ),
                                                                    _c("br"),
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item
                                                                          .user
                                                                          .firstName
                                                                      ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          item
                                                                            .user
                                                                            .name
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _c(
                                  "b-row",
                                  [
                                    _c("b-col", [
                                      _c(
                                        "div",
                                        { staticClass: "empty-state" },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  5525,
                                                  "Currently you have no green suppliers"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                            _c("loading", {
                              attrs: {
                                active: _vm.isLoading,
                                "is-full-page": true,
                              },
                              on: {
                                "update:active": function ($event) {
                                  _vm.isLoading = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("MappedSuppliersModal", {
        key: "mapped-supplier-modal",
        attrs: {
          suppliers: _vm.GreenSuppliers,
          title: _vm.FormMSG(8765786, "Find your green supplier"),
        },
        on: { selected: _vm.handleSelectedGreenSupplier },
        model: {
          value: _vm.isMappedSupplierModalOpen,
          callback: function ($$v) {
            _vm.isMappedSupplierModalOpen = $$v
          },
          expression: "isMappedSupplierModalOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }